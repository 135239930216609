import React from 'react';
import { graphql } from 'gatsby';

import SEO from "../../../components/seo";
import Header from '../../../components/Header';
import KnowledgeCenter from '../../../components/KnowledgeCenter';
import Footer from '../../../components/Footer';
import GoTop from '../../../components/GoTop';
import GoTopMobile from '../../../components/GoTopMobile';
import GlobalStyles from '../../../styles/GlobalStyles';

import {
  Wrapper,
  CategoryCard,
  CategoryTitle,
  CategoryContent,
  CategoryLinks,
  CategoryLinkTitle,
  CategoryLinkDescription,
} from './styles';

const KnowledgeCategories = ({ data }) => {
  const category = data.strapi.category;

  return (
    <>
      <SEO
        title={`SmartEnvios: ${category.title}`}
      />
      <Header />
      <KnowledgeCenter />

      <Wrapper>
        <CategoryTitle><span>Veja tudo sobre</span>{` ${category.title}`}</CategoryTitle>
        <CategoryCard id="doc">

          <CategoryContent>
            {category.knowledge.map(e => (
              <CategoryLinks
                key={e.id}
                cover
                bg="#fff"
                to={`/${e.class.slug}/${e.slug}`}
              >
                <CategoryLinkTitle>{e.title}</CategoryLinkTitle>
                <CategoryLinkDescription>{e.description}</CategoryLinkDescription>
              </CategoryLinks>
            ))}
          </CategoryContent>

        </CategoryCard>
      </Wrapper>

      <GoTop />
      <GoTopMobile />
      <Footer />
      <GlobalStyles />
    </>
  );
}

export default KnowledgeCategories;

export const query = graphql`
  query KnowledgeTemplae($id: ID!) {
    strapi {
      category(id: $id) {
        title
        slug
        knowledge {
          title
          slug
          description
          id
          class {
            slug
          }
        }
      }
    }
  }
`;