import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const Wrapper = styled.div`
  padding: 20px 200px;
  width: auto;
  height: auto;
  z-index: 0;

  @media (max-width: 1200px) {
    padding: 0 100px 0;
  }

  @media (max-width: 800px) {
    padding: 0 20px 0;
  }

  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0;
  }
`;

export const CategoryCard = styled.div`
`;

export const CategoryTitle = styled.h2`
  font-size: 32px;
  font-weight: bold;
  font-family: 'Sora', sans-serif;
  color: #F58634;
  text-align: center;

  > span {
    color: #044D75;
  }

  @media (max-width: 1200px) {
    margin-top: 20px;
  }

  @media (max-width: 400px) {
    font-size: 30px;
  }
`;

export const CategoryContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  padding: 10px;

  @media (max-width: 675px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

export const CategoryLinks = styled(AniLink)`
  margin-top: 20px;
  color: #546b81;
  background: #eee;
  padding: 20px 30px;
  text-decoration: none;
  border-radius: 6px;

  &:hover {
    color: #489be8;
  }
`;

export const CategoryLinkTitle = styled.h3`
  color: #044D75;
  font-size: 16px;
`;

export const CategoryLinkDescription = styled.p`
  margin-top: 10px;
  font-size: 14px;
`;